<template>
    <div id="header-footer-modal-preview" class="modal-form" :class="{ visible }" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="font-medium text-base mr-auto">
                        {{ title }}
                    </h2>

                    <div>
                        <a
                            v-if="toLink"
                            :href="`https://kruizy-rossii.com/${toLink}`"
                            target="_blank"
                            class="btn btn-outline-secondary w-30 mr-2"
                            :class="{ 'cursor-wait pointer-events-none opacity-50': loading }"
                        >
                            Перейти на страницу
                        </a>
                        <button
                            type="button"
                            data-dismiss="modal"
                            class="btn btn-outline-secondary w-20 mr-1"
                            @click.prevent="closeModal"
                        >
                            Закрыть
                        </button>
                    </div>
                </div>

                <div class="modal-body gap-4 gap-y-3">
                    <slot></slot>
                </div>

                <div class="modal-footer text-right">
                    <button
                        v-if="hasSaveButton"
                        type="button"
                        class="btn btn-danger w-30 mr-2"
                        @click.prevent="saveOnlyAction"
                    >
                        Сохранить
                    </button>
                    <button
                        v-if="hasSaveExitButton"
                        type="button"
                        class="btn btn-danger w-80 mr-2"
                        @click.prevent="saveAction"
                    >
                        Сохранить и закрыть
                    </button>
                    <button
                        type="button"
                        data-dismiss="modal"
                        class="btn btn-outline-secondary w-20 mr-1"
                        @click.prevent="closeModal"
                    >
                        Закрыть
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CreateUpdateModal',
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: 'Редактирование',
        },
        hasSaveExitButton: {
            type: Boolean,
            default: true,
        },
        hasSaveButton: {
            type: Boolean,
            default: true,
        },
        toLink: {
            type: String,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        closeModal() {
            this.$emit('closeActionHandler');
        },
        saveAction() {
            this.$emit('saveActionHandler', this.modalData);
        },
        saveOnlyAction() {
            this.$emit('saveOnlyActionHandler', this.modalData);
        },
    },
};
</script>

<style scoped lang="scss">
.modal-form {
    @apply z-pull;

    position: fixed;
    right: 16px;
    left: 16px;
    bottom: -100%;
    transition: bottom 0.3s ease 0s;

    &.visible {
        bottom: 0;
    }

    .modal-content {
        height: 92vh;
        border-radius: 10px 10px 0 0;

        .modal-body {
            min-height: 86%;
            max-height: 86%;
            overflow: auto;
        }
    }
}
</style>
