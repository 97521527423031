<template>
    <form class="create-form">
        <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
            <Preloader />
        </div>

        <div class="grid grid-cols-12 gap-6 mt-4">
            <div class="relative col-span-6">
                <label for="form-name" class="form-label">
                    Название
                    <sup v-if="v$.form.name.required" class="text-theme-6">*</sup>
                </label>
                <label v-if="v$.form.name.$errors.length" for="form-name" class="form-input-text-error">
                    {{ getOverrideMessage(v$.form.name.$errors[0].$message) }}
                </label>
                <input
                    id="form-name"
                    v-model="form.name"
                    type="text"
                    class="form-control w-full"
                    :class="{ 'form-input-error': v$.form.name.$errors.length }"
                    placeholder=""
                    autocomplete="off"
                    :data-value="form.name"
                />
            </div>

            <div class="col-span-6">
                <label for="form-category_id" class="form-label">Категория</label>
                <select
                    id="form-category_id"
                    v-model="form.category_id"
                    name="type"
                    class="form-select sm:mr-2"
                    autocomplete="off"
                >
                    <option value="">Нет</option>
                    <option
                        v-for="(category, optIdx) in categories"
                        :key="optIdx"
                        :value="category.id"
                        :selected="form.category_id === category.id"
                    >
                        {{ category.name }}
                    </option>
                </select>
            </div>

            <div class="relative col-span-6">
                <label for="form-header" class="form-label">
                    Заголовок
                    <sup v-if="v$.form.header.required" class="text-theme-6">*</sup>
                </label>
                <label v-if="v$.form.header.$errors.length" for="form-header" class="form-input-text-error">
                    {{ getOverrideMessage(v$.form.header.$errors[0].$message) }}
                </label>
                <input
                    id="form-header"
                    v-model="form.header"
                    type="text"
                    class="form-control w-full"
                    :class="{ 'form-input-error': v$.form.header.$errors.length }"
                    placeholder=""
                    autocomplete="off"
                    :data-value="form.header"
                />
            </div>

            <div class="col-span-6">
                <label for="form-sub_header" class="form-label">Подзаголовок</label>
                <input
                    id="form-sub_header"
                    v-model="form.sub_header"
                    type="text"
                    class="form-control w-full"
                    placeholder=""
                    autocomplete="off"
                    :data-value="form.sub_header"
                />
            </div>

            <div class="relative col-span-6">
                <label for="form-slug" class="form-label">
                    Ссылка
                    <sup v-if="v$.form.slug.required" class="text-theme-6">*</sup>
                </label>
                <label v-if="v$.form.slug.$errors.length" for="form-slug" class="form-input-text-error">
                    {{ getOverrideMessage(v$.form.slug.$errors[0].$message) }}
                </label>
                <input
                    id="form-slug"
                    v-model="form.slug"
                    type="text"
                    class="form-control w-full"
                    :class="{ 'form-input-error': v$.form.slug.$errors.length }"
                    placeholder=""
                    autocomplete="off"
                    :data-value="form.slug"
                />
            </div>

            <div class="col-span-6">
                <label for="form-published" class="form-label">Опубликовано</label>
                <select id="form-published" v-model="form.published" class="form-select">
                    <option value="1">Опубликовано</option>
                    <option value="0">Не опубликовано</option>
                </select>
            </div>

            <div class="relative col-span-12" :class="{ 'form-editor-error': v$.form.intro_text.$errors.length }">
                <label class="form-label">
                    Предварительный текст
                    <sup v-if="v$.form.intro_text.required" class="text-theme-6">*</sup>
                </label>
                <label v-if="v$.form.intro_text.$errors.length" class="form-editor-text-error">
                    {{ getOverrideMessage(v$.form.intro_text.$errors[0].$message) }}
                </label>
                <CustomWyswig v-model="form.intro_text" />
            </div>

            <div class="relative col-span-12" :class="{ 'form-editor-error': v$.form.full_text.$errors.length }">
                <label class="form-label">
                    Полный текст
                    <sup v-if="v$.form.full_text.required" class="text-theme-6">*</sup>
                </label>
                <label v-if="v$.form.full_text.$errors.length" class="form-editor-text-error">
                    {{ getOverrideMessage(v$.form.full_text.$errors[0].$message) }}
                </label>
                <CustomWyswig v-model="form.full_text" />
            </div>

            <div class="col-span-12">
                <label class="form-label">Изображение</label>
                <UploadImageNew
                    ref="update_image"
                    v-model="form.image"
                    :delete="form.image_delete"
                    recommended-sizes="1170х300px"
                />
            </div>

            <div class="col-span-12">
                <h4 class="font-bold text-xl mb-3">Seo</h4>
                <label for="form-seo_title" class="form-label">Seo заголовок</label>
                <input
                    id="form-seo_title"
                    v-model="form.seo_title"
                    type="text"
                    class="form-control w-full"
                    placeholder=""
                    autocomplete="off"
                    :data-value="form.seo_title"
                />
            </div>

            <div class="col-span-12">
                <label class="form-label">Seo текст</label>
                <CustomWyswig v-model="form.seo_text" />
            </div>

            <div class="col-span-12">
                <label class="form-label font-bold">Акции</label>
                <p v-if="promotionOptions.length === 0">Отсутствуют</p>
                <MultiselectTable
                    v-else
                    v-model="form.promotions"
                    :header-labels="promotionTableLabels"
                    :options="promotionOptions"
                    :track-by="promotionMultiselectTrackBy"
                />
            </div>

            <div class="col-span-12">
                <label class="form-label font-bold">Кастомные поля</label>
                <TeamsTable v-model="seo" :meta="null" :headers="seoHeaders" @update:modelValue="updateSeo" />
            </div>
        </div>

        <div class="col-span-12 flex items-center justify-end mt-5">
            <router-link :to="{ name: 'pages' }" class="btn btn-secondary w-24 mr-2 mb-2">Отмена</router-link>
            <button type="submit" class="btn btn-primary mb-2 mr-2" @click.prevent="saveAndQuit">
                Сохранить и выйти
            </button>
            <button type="submit" class="btn btn-primary mb-2" @click.prevent="saveAndEdit">Сохранить</button>
        </div>
    </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import Preloader from '@/components/preloader/Main';
import UploadImageNew from '@/components/upload-image/UploadImageNew.vue';
import CustomWyswig from '@/components/base/custom-wyswig/Main';
import TeamsTable from '@/components/tables/TeamsTable';
import MultiselectTable from '@/components/tables/MultiselectTable';
import { errorResponse } from '@/mixins/form/form-mixin';
import { helper as $h } from '@/utils/helper';
import { formPreparation } from '@/utils/object-to-form-data';

export default {
    name: 'CreatePage',
    components: { Preloader, UploadImageNew, CustomWyswig, TeamsTable, MultiselectTable },
    mixins: [errorResponse],
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            loading: false,
            categories: [],
            form: {
                name: '',
                category_id: '',
                header: '',
                sub_header: '',
                slug: '',
                image: '',
                image_delete: null,
                intro_text: '',
                full_text: '',
                extra_fields: [
                    // { type: 'text', label: 'Еще одно поле', code: 'code_name', value: null }
                ],
                published: 0,
                promotions: [],
                image_title: null,
                image_alt: null,
            },
            seo: [
                { code: 'title', value: '' },
                { code: 'keywords', value: '' },
                { code: 'description', value: '' },
            ],
            seoHeaders: [
                { field: 'code', label: 'Название параметра' },
                { field: 'value', label: 'Значение' },
            ],
            promotionTableLabels: ['Акция'],
            promotionMultiselectTrackBy: 'name',
            promotionOptions: [],
        };
    },
    validations() {
        return {
            form: {
                name: { required },
                header: { required },
                slug: { required },
                intro_text: { required },
                full_text: { required },
            },
        };
    },
    async created() {
        this.$store.commit('main/setPageHeader', 'Создать страницу');

        await this.fetchDataForFilling();
    },
    methods: {
        async fetchDataForFilling() {
            this.loading = true;

            try {
                const { data } = await this.axios.get('/pages/create');
                this.categories = data.categories;
                this.promotionOptions = data.promotions;
            } catch (ex) {
                console.log('cant fetch categories: ' + ex);
            } finally {
                this.loading = false;
            }
        },
        async save() {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                this.$notify({ text: 'Заполните все поля правильно', type: 'error' });
                return;
            }

            this.loading = true;
            this.form.seo = this.seo;

            try {
                const formData = formPreparation(this.form, { nullsAsUndefineds: false, imagesAsObject: true });
                const { data } = await this.axios.post('/pages', formData);
                this.$notify(data.message);
                this.loading = false;
                return true;
            } catch (ex) {
                this.errorResponse(ex);
                this.loading = false;
                return false;
            }
        },
        async saveAndQuit() {
            const success = await this.save();
            if (success) this.$router.push({ name: 'pages' });
        },
        async saveAndEdit() {
            const success = await this.save();
            if (success) this.$router.push({ name: 'pages', query: { id: data.data.id } });
        },
        getOverrideMessage(message) {
            return $h.getOverrideMessage(message);
        },
    },
};
</script>

<style scoped></style>
